import React, {useEffect, useState} from "react";
import {CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {useStyles} from "./PhaseStyle";
import Button from "@material-ui/core/Button";
import CheckIcon from '@material-ui/icons/Check'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Status from "../Revision/Status";
import PhaseEdit from "./PhaseEdit";
import PhaseDialog from "./PhaseDialog";

const PhaseTable = ({dhf}) => {

    const classes           = useStyles();
    const [refs, setRefs]    = useState([]);
    const [open, setOpen]    = useState(false);

    const checkout = (uuid) => {
        fetch(process.env.REACT_APP_BACKENDURL+'/phase_rev/checkout/'+uuid).then(() => reload());
    }

    const checkin = (uuid) => {
        fetch(process.env.REACT_APP_BACKENDURL+'/phase_rev/checkin/'+uuid).then(() => reload());
    }

    const reload = () => {
        fetch(process.env.REACT_APP_BACKENDURL+'/phase_rev/'+dhf.uuid)
            .then(refs => refs.json())
            .then(refs => setRefs(refs));
    }

    const handleClose = () => {
        setOpen(false);
        reload();
    }

    const handleOpen = () => {
        setOpen(true);
    }

    const showPhase = (phaserev) => {
        console.log(phaserev);
    }

    useEffect(() => {
        reload();
    }, []);

    if(refs.length === 0) {
        return (
            <div>
                <PhaseDialog open={open} dhf={dhf} handleClose={handleClose} />
                <Button onClick={handleOpen} variant="outlined">Neue Phase</Button><br/><br/>
                <CircularProgress />
            </div>
        )
    }

    return (
        <div>
            <PhaseDialog open={open} dhf={dhf} handleClose={handleClose} />
            <Button onClick={handleOpen} variant="outlined">Neue Phase</Button>
            <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell align="right">Name</TableCell>
                            <TableCell align="right">Phase</TableCell>
                            <TableCell align="right">Revision</TableCell>
                            <TableCell align="right">Status</TableCell>
                            <TableCell align="right">Datum</TableCell>
                            <TableCell align="right">Aktionen</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {refs && refs.map((row) => (
                            <TableRow key={row.uuid} onDoubleClick={() => showPhase(row)}>
                                <TableCell component="th" scope="row">
                                    {row.id}
                                </TableCell>
                                <TableCell align="right">{row.name}</TableCell>
                                <TableCell align="right">{row.phase.id}</TableCell>
                                <TableCell align="right">{row.revision}</TableCell>
                                <TableCell align="right"><Status status={row.status}/></TableCell>
                                <TableCell align="right">{row.updated}</TableCell>
                                <TableCell align="right">
                                    {row.status === "checkout" && <PhaseEdit value={row.name} uuid={row.uuid} reload={reload}/>}
                                    {row.status === "checkout" && <Button size="small" variant="outlined" title="checkin" onClick={(id) => checkin(row.uuid)}><CheckIcon/></Button>}
                                    {row.status === "edit" && <Button size="small" variant="outlined" title="checkout" onClick={(id) => checkout(row.uuid)}><ArrowDownwardIcon/></Button>}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};


export default PhaseTable;