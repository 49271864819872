import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DescriptionIcon from '@material-ui/icons/Description';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import DocDialog from "./DialogSelect";
import Button from '@material-ui/core/Button';
import { useStyles } from './DocumentStyle';


const CustomizedDialogs = ({ open, handleClose }) => {
  const classes = useStyles();
  return (
    <Dialog aria-labelledby="customized-dialog-title" open={open} className={classes.DialogContainer}>
      <div className={classes.SubContainer}>
        <div className={classes.HeaderWrapper}>
          <div className={classes.HeadingWrapper}>
            <DescriptionIcon className={classes.DescriptionIcon} />
            <h2 className={classes.Heading}>Neues Dokument erstellen</h2>
          </div>
          <CloseIcon className={classes.CloseIcon} onClick={handleClose} />
        </div>
        <div className={classes.FieldsWrapper}>
          <TextField id="outlined-basic" label="Titel" variant="outlined" className={classes.TitileInput} />
          <DocDialog label="Prozessart" />
          <DocDialog label="Dokumentenart" />
        </div>
        <Button variant="contained" className={classes.CreatButton}>
          dokument erstellen
        </Button>
      </div>
    </Dialog>
  );
}

export default CustomizedDialogs
