import React, {useState} from "react";
import {useStyles} from "./ProjectCardStyle";
import {useTranslation} from "react-i18next";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import {CardHeader} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {Link} from "react-router-dom";

const ProjectCard = ({item}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Card className={classes.root}>

            <CardActionArea component={Link} to={{ pathname: '/project/'+item.uuid }} >
                <CardHeader
                    action={
                        <IconButton aria-label="settings">
                            <MoreVertIcon />
                        </IconButton>
                    }
                />
                <CardMedia
                    className={classes.media}
                    image={item.image ? process.env.REACT_APP_BACKENDURL+'/file/download/'+item.image : "https://weber-instrumente.com/library/pg-orthopaedische-instrumente/orth02.png"}
                    title="Weber Orhopädie"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {item.name} (#{item.id})
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {item.description === "" ? t("noprojectdescription") : item.description}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default ProjectCard