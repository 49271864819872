import React, {useState} from "react";
import {useTranslation} from "react-i18next";

import { useStyles } from '../Document/DocumentStyle';
import ChipInput from "material-ui-chip-input";

const PhaseAddList = ({phases, setPhases}) => {
    const classes               = useStyles();
    const {t}                   = useTranslation();

    const handleChange = (chips) => {
        setPhases(chips)
    }

    return (
        <ChipInput
            variant="outlined"
            placeholder={t('typetoaddphases')}
            className={classes.TitileInput}
            onChange={(chips) => handleChange(chips)}
        />
    )
}

export default PhaseAddList