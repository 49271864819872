import Dialog from '@material-ui/core/Dialog';
import DescriptionIcon from '@material-ui/icons/Description';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { useStyles } from '../Document/DocumentStyle';
import {CircularProgress} from "@material-ui/core";
import {useState} from "react";

/**
 *
 * @param open
 * @param submittable
 * @param handleClose
 * @param handleSubmit
 * @param handleFields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

const CustomizedDialogs = ({ open, submittable, handleClose, handleSubmit, handleFields, ...props}) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const submitData = () => {
        setLoading(true);
        handleSubmit();
        setLoading(false);
    }

    return (
        <Dialog aria-labelledby="customized-dialog-title" open={open} className={classes.DialogContainer}>
            <div className={classes.SubContainer}>
                <div className={classes.HeaderWrapper}>
                    <div className={classes.HeadingWrapper}>
                        <DescriptionIcon className={classes.DescriptionIcon} />
                        <h2 className={classes.Heading}>{props.heading || "Dialog"}</h2>
                    </div>
                    <CloseIcon className={classes.CloseIcon} onClick={handleClose} />
                </div>
                <div className={classes.FieldsWrapper}>
                    {handleFields()}
                </div>
                <div className={classes.wrapper}>
                    <Button variant="contained" disabled={!submittable && !loading} className={classes.CreatButton} onClick={submitData}>
                        {props.submittitle || "speichern"}
                    </Button>
                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
            </div>
        </Dialog>
    );
}

export default CustomizedDialogs
