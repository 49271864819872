import React, {useEffect, useState} from "react";
import { useStyles } from "./SettingsStyle";
import { useTranslation } from 'react-i18next';
import {Link} from "react-router-dom";


const Settings = () => {
  const classes                 = useStyles();
  const { t }                   = useTranslation();

  return (
    <div className={classes.MainContainer}>
      <div className={classes.SubMenu}>
        <a className={classes.HomeHeading}>{t('settings')}</a>
      </div>
      <h1 className={classes.MainHeading}>{t('settings')}</h1>
      <div className={classes.HomeWrapper}>
          <Link to={{pathname: "/settings/requirements"}}>{t('requirements')}</Link>
      </div>
    </div>
  );
};

export default Settings;