import React, {useEffect, useState} from "react";
import { useStyles } from "../SettingsStyle";
import { useTranslation } from 'react-i18next';
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import RequirementDialog from "./RequirementDialog";
import RequirementTable from "./RequirementTable";
import {useParams} from "react-router";
import PdeEditor from "../../Editor/PdeEditor";


const RequirementEditor = () => {
  const classes                 = useStyles();
  const { t }                   = useTranslation();
  const {uuid}              = useParams();
  const [ref, setRef]         = useState([]);
  const [content, setContent]         = useState();

    const reload = () => {
        fetch(process.env.REACT_APP_BACKENDURL+'/requirement_rev/'+uuid, {mode: "cors"})
            .then(requirement => requirement.json())
            .then(requirement => setRef(requirement))
    }

    useEffect(() => {
        reload();
    }, []);

  return (
    <div className={classes.MainContainer}>
      <div className={classes.SubMenu}>
        <a className={classes.HomeHeadingSecondary}>{t('settings')}</a>
        <span className={classes.divider}>/</span>
          <a className={classes.HomeHeadingSecondary}>{t('requirements')}</a>
          <span className={classes.divider}>/</span>
        <a className={classes.HomeHeading}>{t('requirementsedit')}</a>
      </div>
      <h1 className={classes.MainHeading}>{t('requirementsedit')}</h1>
      <div className={classes.HomeWrapper}>
          {ref && <PdeEditor setContent={setContent} value={ref.content} updateUrl={process.env.REACT_APP_BACKENDURL+'/requirement_rev/'+uuid} />}
      </div>
    </div>
  );
};

export default RequirementEditor;