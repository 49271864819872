import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    marginBottom: 10,
  },
  saveAndBackButton: {

  },
  saveButton: {
    marginRight: 10,
  }
}));
