import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  released: {
    color: "green",
  },
  edit: {
    color: "gray",
  },
  outdated: {
    color: "darkred",
    borderColor: "darkred",
  },
  checkout: {
    color: "blue",
    borderColor: "blue",
  }
}));
