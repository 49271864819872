import React, {useEffect, useState} from "react";
import { useStyles } from "./PhaseStyle";
import Carousel from "react-material-ui-carousel";
import Dataset from "../Datasets/Dataset";
import Grid from "@material-ui/core/Grid";
import {Paper} from "@material-ui/core";


const PhaseOverview = ({phases}) => {
  const classes                 = useStyles();
  const [current, setCurrent]   = useState();

  const Item = (item) =>
    {
        return (
            <Paper>
                <Grid container className={classes.root} spacing={0}>
                    <Grid item className={classes.status}>14/15</Grid>
                    <Grid item className={classes.phase}>{item.name}</Grid>
                    <Grid item className={classes.revision}>Rev. 1 / 02..11.2021</Grid>
                </Grid>
            </Paper>
        )
    }

    const handleChange = (param) => {
      setCurrent(phases[param]);
    }

    useEffect(() => {
        if(phases) setCurrent(phases[0]);
    }, []);

  return (
      <div style={{height: 400, width: "100%"}}>
          <Carousel autoPlay={false} navButtonsAlwaysVisible={true} timeout={400} onChange={handleChange}>
              {
                  phases.map((p) => Item(p))
              }
          </Carousel>
          {current && <Dataset phase={current} />}
      </div>
  );
};


export default PhaseOverview;