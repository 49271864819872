import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  MainContainer: {
    height: "100vh",
    width: 292,
    backgroundColor: "#fafafa",
    ['@media (max-width: 1600px)']: {
      height: "auto",
    },
    ['@media (min-width: 2500px)']: {
      height: "92vh",
    },
    ['@media (min-width: 3000px)']: {
      height: "93vh",
    },
  },
  drawerPaper: {
    width: 299,
    position: "sticky",
    backgroundColor: "#fafafa",
    borderRight: 0,
  },
  ListWrapper: {
    margin: "10px 0px 0px 0px",
    cursor: "pointer",
    "&:hover": {
      width: 292,
      background: "#e5e5e5",
    },
    "& .MuiTypography-body1": {
      color: theme.colors.primary.DrawerListColor,
      fontFamily: theme.fonts.family.primary,
      fontSize: theme.fonts.sizes.fontXL,
      fontWeight: "normal",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.6em",
    },
  },
  Active: {
    background: "#e5e5e5",
    width: "290px !important",
    "& .MuiTypography-body1": {
      color: theme.colors.primary.DrawerListActiveColor,
      fontWeight: 500,
    },
    "& .MuiSvgIcon-root": {
      color: theme.colors.secondary.IconActiveColor,
      opacity: 0.54
    },
  },
  Divider: {
    marginTop: 20,
    color: "rgba(0, 0, 0, 0.12)",
    width: 290,

  },
  Heading: {
    fontSize: theme.fonts.sizes.fontXL,
    fontFamily: theme.fonts.family.primary,
    color: "rgba(0, 0, 0, 0.4)",
    margin: "20px 0px 0px 19px",
  }
}));
