import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import BusinessIcon from '@material-ui/icons/Business';
// import TextField from '@material-ui/core/TextField';
// import DocDialog from "./DialogSelect";
// import Button from '@material-ui/core/Button';
import { useStyles } from './CompanyOverviewStyle';


const CustomizedDialogs = ({ open, handleClose }) => {
  const classes = useStyles();
  return (
    <Dialog aria-labelledby="customized-dialog-title" open={open} className={classes.DialogContainer}>
      <div className={classes.SubContainer}>
        <div className={classes.HeaderWrapper}>
          <div className={classes.HeadingWrapper}>
            <BusinessIcon className={classes.DescriptionIcon} />
            <h2 className={classes.Heading}>Neue Firma hinzufügen</h2>
          </div>
          <CloseIcon className={classes.CloseIcon} onClick={handleClose} />
        </div>
      </div>
    </Dialog>
  );
}

export default CustomizedDialogs
