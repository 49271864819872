import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  MainContainer: {
    margin: "30px 0px 0px 35px",
  },
  button: {
    position: "absolute",
    left: "40%",
    bottom: "0px",
    marginLeft: "-50px",
    marginBottom: "10px",
    background: "#41bdb1 !important",
    fontFamily: theme.fonts.family.primary,
    fontSize: "14px !important",
    fontWeight: "normal !important",
    width: 206,
    height: 48,
    borderRadius: "24px !important",
    ['@media (min-width: 2500px)']: {
      left: "45%",
    },
    ['@media (min-width: 3800px)']: {
      left: "45%",
    },
  },
  qsistantHeading: {
    fontFamily: theme.fonts.family.primary,
    fontSize: theme.fonts.sizes.fontL,
    color: "#333333",
    fontWeight: "normal",
    opacity: 0.7,
    cursor: "pointer",
  },
  divider: {
    margin: "0px 8px 0px 8px",
    color: "#777777"
  },
  HomeHeading: {
    fontFamily: theme.fonts.family.primary,
    fontSize: theme.fonts.sizes.fontL,
    fontWeight: 500,
    color: "#000000",
    cursor: "pointer",
  },
  HomeHeadingSecondary: {
    fontFamily: theme.fonts.family.primary,
    fontSize: theme.fonts.sizes.fontL,
    color: "gray",
  },
  MainHeading: {
    fontFamily: theme.fonts.family.primary,
    fontSize: theme.fonts.sizes.font3XLL,
    fontWeight: 500,
    color: "#747474"
  },
  DocCompWrapper: {
    display: "flex",
    // flexWrap: "wrap",
    // justifyContent: "space-between",
  },
  HomeWrapper: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    ['@media (max-width: 1600px)']: {
      //width: "100%",
    },
    ['@media (min-width: 3800px)']: {
      //width: "91vw",
    },
    ['@media (min-width: 2000px)']: {
      //width: "83vw",
    },
    ['@media (min-width: 2500px)']: {
      //width: "86vw",
    },
    ['@media (min-width: 3000px)']: {
      //width: "88vw",
    },

  }
}));
