import ListIcon from "@material-ui/icons/List";
import SettingsIcon from '@material-ui/icons/Settings';

export const FirstSectionArray = [

  {
    Name: 'projectoverview',
    icon: ListIcon,
    path: "/home",
    id: 1,
  },
  {
    Name: 'settings',
    icon: SettingsIcon,
    path: "/settings",
    id: 2,
  },
];