import React, {useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DescriptionIcon from '@material-ui/icons/Description';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useStyles } from '../Document/DocumentStyle';
import {useTranslation} from "react-i18next";
import {CircularProgress} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';


const CustomizedDialogs = ({ open, handleClose, project }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  let [name, setName] = useState("");
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState();
  let [submitable, setSubmitable] = useState(false);

  const submitDhf = () => {
    setLoading(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        name: name,
        project: project.id
      }),
      mode: "cors",
    };

    let code = 0;
    fetch(process.env.REACT_APP_BACKENDURL+'/dhf/', requestOptions)
        .then(function(response) {                      // first then()
          if(response.ok || response.status === 400)
          {
            code = response.status;
            return response.json();
          }

          throw new Error("fatal error");
        })
        .then(values => {
          if(code === 400) {
            setErrors(values);
          } else {
            setName("");
            handleClose();
          }
          setLoading(false);
        }).catch(e => {
          //setErrors(e)
          console.log(e);
          setLoading(false);
        });
      }

  useEffect(() => {
    setSubmitable(name.length > 0);
  });

  const handleNameChange = (e) => {
    setName(e.target.value);
  }

  return (
    <Dialog aria-labelledby="customized-dialog-title" open={open} className={classes.DialogContainer}>
      <div className={classes.SubContainer}>
        <div className={classes.HeaderWrapper}>
          <div className={classes.HeadingWrapper}>
            <DescriptionIcon className={classes.DescriptionIcon} />
            <h2 className={classes.Heading}>{t('newdhf')}</h2>
          </div>
          <CloseIcon className={classes.CloseIcon} onClick={handleClose} />
        </div>
        <div className={classes.FieldsWrapper}>
          {errors && errors.map((e) => {return <Alert variant="standard" severity="error">{e.message}</Alert>})}
        </div>
        <div className={classes.FieldsWrapper}>
          <TextField id="outlined-basic" label={t('dhfname')} variant="outlined" value={name} onChange={handleNameChange} className={classes.TitileInput} />
        </div>
        <div className={classes.wrapper}>
          <Button variant="contained" disabled={!submitable && !loading} className={classes.CreatButton} onClick={submitDhf}>
            {t('dhfcreate')}
          </Button>
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </div>
    </Dialog>
  );
}

export default CustomizedDialogs
