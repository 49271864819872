import React, {useEffect, useState} from "react";
import {useStyles} from "./DhfDetailsStyle";
import Status from "../Revision/Status";
import {CircularProgress} from "@material-ui/core";


const DhfRevision = ({rev, ...props}) => {

    const classes           = useStyles();


    if(!rev) {
        return (
            <CircularProgress/>
        )
    }



    return (
        <div>
            NAme: {rev.name}<br/>
            UUID: {rev.uuid}<br/>
            DHF ID: {rev.dhf}<br/>
            Status: <Status status={rev.status} />
        </div>
    );
};


export default DhfRevision;