import React from 'react';
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "././Theme/theme";

// importing Routes
import Routes from "../src/Routes/Routes"

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  );
}

export default App;
