import React from "react";
import { useStyles } from "./CompanyProfileStyle";

const CompnayRow = () => {
  const classes = useStyles();

  return (
    <div className={classes.RowContainer}>
      <div className={classes.RowInnerContent}>
        <p className={classes.EmailText}>E-Mail</p>
        <p className={classes.Email}>Max.Mustermann@MiQ.com.de</p>
        <div className={classes.ChangeWrapper}>
          <p className={classes.ChangeText}>Ändern</p>
        </div>
      </div>
    </div>
  )
}

export default CompnayRow