import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  MainContainer: {
    margin: "30px 0px 0px 35px"
  },
  ProfilHeading: {
    fontFamily: theme.fonts.family.primary,
    fontSize: theme.fonts.sizes.fontL,
    color: "#333333",
    fontWeight: "normal",
    opacity: 0.7,
    cursor: "pointer",
  },
  divider: {
    margin: "0px 8px 0px 8px",
    color: "#777777"
  },
  overviewHeading: {
    fontFamily: theme.fonts.family.primary,
    fontSize: theme.fonts.sizes.fontL,
    fontWeight: 500,
    color: "#000000",
    cursor: "pointer",
  },
  MainHeading: {
    fontFamily: theme.fonts.family.primary,
    fontSize: theme.fonts.sizes.font3XLL,
    fontWeight: 500,
    color: "#747474"
  },
  ProfileContainer: {
    height: 280,
    background: "white",
    ['@media (max-width: 1600px)']: {
      width: "1170px",
    },
    ['@media (min-width: 2000px)']: {
      width: "83vw",
    },
    ['@media (min-width: 2500px)']: {
      width: "86vw",
    },
    ['@media (min-width: 3000px)']: {
      width: "88vw",
    },
  },
  profileInnerContent: {
    padding: "0px 20px",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  ProfileAvatar: {
    height: 215,
    width: 205,
  },
  AvatarWrapper: {
    display: "flex",
    alignItems: "center",
  },
  userNameWrapper: {
    marginLeft: 30
  },
  UserName: {
    fontFamily: theme.fonts.family.primary,
    fontSize: theme.fonts.sizes.fontL,
    fontWeight: "normal",
    color: "#777777",
    marginBottom: 5,
  },
  Maxime: {
    fontFamily: theme.fonts.family.primary,
    fontSize: theme.fonts.sizes.fontXL,
    fontWeight: 500,
    color: "#373a3c",
    margin: 0,
  },
  Abbreviation: {
    fontFamily: theme.fonts.family.primary,
    fontSize: theme.fonts.sizes.fontL,
    fontWeight: "normal",
    color: "#777777",
    marginBottom: 5,
  },
  subText: {
    fontFamily: theme.fonts.family.primary,
    fontSize: theme.fonts.sizes.fontXL,
    fontWeight: 500,
    color: "#373a3c",
    margin: 0,
  },
  ChangeWrapper: {
    border: "solid 2px #373a3c",
    borderRadius: 20,
    padding: "7px 15px",

  },
  ChangeText: {
    fontFamily: theme.fonts.family.primary,
    fontSize: theme.fonts.sizes.fontL,
    fontWeight: 500,
    color: "#373a3c",
    margin: 0
  },

  //ROW styling

  RowContainer: {
    height: 120,
    background: "white",
    marginTop: 20,
    ['@media (max-width: 1600px)']: {
      width: "1170px",
    },
    ['@media (min-width: 2000px)']: {
      width: "83vw",
    },
    ['@media (min-width: 2500px)']: {
      width: "86vw",
    },
    ['@media (min-width: 3000px)']: {
      width: "88vw",
    },
  },
  RowInnerContent: {
    height: "100%",
    padding: "0px 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  EmailText: {
    fontFamily: theme.fonts.family.primary,
    fontSize: theme.fonts.sizes.fontXL,
    color: "#373a3c",
    fontWeight: "normal"
  },
  Email: {
    fontFamily: theme.fonts.family.primary,
    fontSize: theme.fonts.sizes.fontXL,
    color: "#373a3c",
    fontWeight: "normal"
  }
}));
