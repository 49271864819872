import React, {useEffect, useState} from 'react';
import Dialog from '../../Dialog/Dialog'
import TextField from '@material-ui/core/TextField';
import { useStyles } from '../../Document/DocumentStyle';
import {useTranslation} from "react-i18next";


const RequirementDialog = ({handleClose, open, requirement }) => {
    const classes   = useStyles();
    const { t }     = useTranslation();

    let [name, setName]                 = useState("");
    let [submittable, setSubmittable]   = useState(false);

    const fieldRender = () => {
        return (
            <TextField id="outlined-basic" label={t('requirementname')} variant="outlined" value={name} onChange={handleNameChange} className={classes.TitileInput} />
        )
    }
    
    const submit = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                name: name,
            })
        };

        fetch(process.env.REACT_APP_BACKENDURL+'/requirement/', requestOptions).then(values => values.json()).then(values => {
            setName("");
            handleClose();
        });
    }

    useEffect(() => {
        if(requirement) setName(requirement.name);
    }, []);

    useEffect(() => {
        setSubmittable(name.length > 0);
    });

    const handleNameChange = (e) => {
        setName(e.target.value);
    }

    return (
        <Dialog handleClose={handleClose} open={open} submittable={submittable} handleFields={fieldRender} handleSubmit={submit} heading={t('newrequirement')} submittitle={t('requirementcreate')} />
    );
}

export default RequirementDialog
