import { createTheme } from '@material-ui/core/styles';

export default createTheme({
  fonts: {
    family: {
      primary: 'Roboto',
    },
    sizes: {
      fontXXS: 9,
      fontXS: 10,
      fontS: 12,
      fontM: 14,
      fontL: 16,
      fontXL: 18,
      font2XL: 20,
      font3XL: 36,
      font3XLL: 38,
    },
  },
  colors: {
    primary: {
      DrawerListColor: "rgba(0, 0, 0, 0.8)",
    },
    secondary: {
      DrawerListActiveColor: "#000000",
      IconActiveColor: "#d6bd36"
    },
  },
});
