import React, {useEffect, useState} from "react";
import { useStyles } from "./DatasetStyle";
import DatasetTable from "./DatasetTable";


const Dataset = ({phase}) => {
    const classes                 = useStyles();
    const [refs, setRefs]         = useState();

    const reload = () => {
      fetch(process.env.REACT_APP_BACKENDURL+'/dataset_rev/'+phase.uuid)
          .then(refs => refs.json())
          .then(refs => setRefs(refs))
    }

    useEffect(() => {
        reload();
    }, []);

    return (
        <DatasetTable refs={refs} reload={reload}/>
    );
};


export default Dataset;