import React, {useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DescriptionIcon from '@material-ui/icons/Description';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useStyles } from '../Document/DocumentStyle';
import {useTranslation} from "react-i18next";
import {CircularProgress} from "@material-ui/core";
import {DropzoneArea} from "material-ui-dropzone";
import PhaseAddList from "../Phase/PhaseAddList";


const CustomizedDialogs = ({ open, handleClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [phases, setPhases]   = useState([]);
    const [name, setName]                   = useState("");
  const [loading, setLoading]             = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [submittable, setSubmittable]       = useState(false);
  const [description, setDescription]     = useState("");


  const fetchProject = (file = null) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        name: name,
        image: file === null ? null : file.uuid,
        phases: phases,
        description: description
      })
    };
    fetch(process.env.REACT_APP_BACKENDURL+'/project/', requestOptions).then(values => values.json()).then(newProject => {
      setName("");
      setDescription("");
      handleClose();
      setLoading(false);
    }).catch(e => {
      setLoading(false);
    });
  }

  const submitProject = async () => {
    setLoading(true);


    if(selectedFiles.length > 0) {
      const data = new FormData();
      data.append('File', selectedFiles[0]);
      //upload file
      fetch(process.env.REACT_APP_BACKENDURL+'/file/upload?XDEBUG_SESSION_START=PHPSTORM', {
        method: 'POST',
        body: data,
      }).then(response => response.json()).then(file => fetchProject(file));
    } else {
      fetchProject();
    }

    setLoading(false);
  }

  useEffect(() => {
    setSubmittable(name.length > 0);
  });

  const handleNameChange = (e) => {
    setName(e.target.value);
  }
  
  const handleUploadChange = (files) => {
    setSelectedFiles(files);
    console.log(files);
  }

  return (
    <Dialog aria-labelledby="customized-dialog-title" open={open} className={classes.DialogContainer}>
      <div className={classes.SubContainer}>
        <div className={classes.HeaderWrapper}>
          <div className={classes.HeadingWrapper}>
            <DescriptionIcon className={classes.DescriptionIcon} />
            <h2 className={classes.Heading}>{t('projectcreate')}</h2>
          </div>
          <CloseIcon className={classes.CloseIcon} onClick={handleClose} />
        </div>
        <div className={classes.FieldsWrapper}>
          <TextField id="outlined-basic" label={t('projectname')} variant="outlined" value={name} onChange={handleNameChange} className={classes.TitileInput} />
          <TextField id="outlined-basic1" label={t('projectdescription')} variant="outlined" value={description} onChange={e => setDescription(e.target.value)} className={classes.TitileInput} />
          <PhaseAddList id="outlined-basic3" phases={phases} setPhases={setPhases}/>
          <DropzoneArea filesLimit={1} acceptedFiles={['image/*']} onChange={handleUploadChange.bind(this)}/>
        </div>
        <div className={classes.wrapper}>
          <Button variant="contained" disabled={!submittable && !loading} className={classes.CreatButton} onClick={submitProject}>
            {t('projectcreate')}
          </Button>
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </div>
    </Dialog>
  );
}

export default CustomizedDialogs
