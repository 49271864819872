import { useStyles } from "./StatusStyle.js";
import {Chip} from "@material-ui/core";
import {useTranslation} from "react-i18next";

const Status = ({status}) => {
    const classes   = useStyles();
    const {t}       = useTranslation()

    const displayStatus = () => {
        let classname;

        switch (status || "test") {
            case "released":
                    classname = classes.released;
                break;
            case "outdated":
                classname = classes.outdated;
                break;
            case "edit":
                    classname = classes.edit;
                break;
            case "checkout":
            default:
                    classname = classes.checkout;
                break;
        }

        return <Chip variant="outlined" className={classname} size="small" label={t(status)}/>
    }

    return (
        displayStatus()
    );
};

export default Status;