import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    fontFamily: "Roboto",
  },
  phaseAdd: {
    color: "green",
    fontSize: 14,
    cursor: "pointer",
  },
  dhfAdd: {
    color: "green",
    fontSize: 16,
    cursor: "pointer",
  }
}));
