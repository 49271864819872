import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    minWidth: 345,
    display: "flex",
    marginRight: 50,
  },
  media: {
    height: 140,
  },
}));
