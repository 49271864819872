import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  MainContainer: {
    background: "white",
    display: "flex",
    height: "100vh"
  },
  LogoWrapper: {
    flex: 1,
    background: "#f2f2f2",
    maxWidth: 630,
    },
  Logo: {
    fontFamily: theme.fonts.family.primary,
    fontSize: 32,
    color: "#4e4d4d",
    fontWeight: "normal",
    padding: "30px 40px",
    margin: 0,
  },

  //Form styling
  LoginContainer: {
    width: 500,
    padding: "12rem 10rem"
  },
  FormHeading: {
    fontFamily: theme.fonts.family.primary,
    fontSize: 32,
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: "normal",
    marginBottom: 12,
  },
  subTitle: {
    fontFamily: theme.fonts.family.primary,
    fontSize: 21,
    fontWeight: "normal",
    color: 'rgba(0, 0, 0, 0.6)',
    margin: "0px 0px 30px 0px",
  },

  //Login input

  InputContainer: {
    display: "flex",
    flexDirection: "column"
  },
  Emailinput: {
    marginBottom: 20,
  },

  CheckboxWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'center',
    margin: "11px 0px"
  },

  forgetPassword: {
    fontFamily: theme.fonts.family.primary,
    fontWeight: "normal",
    color: "#4199bf"
  },
  Checkbox: {
    fontFamily: theme.fonts.family.primary,
    fontSize: 19,
    fontWeight: "normal",
    color: "rgba(0, 0, 0, 0.6)",
  },
  LoginBtn: {
    background: "#e0d289 !important",
    width: "100%",
    height: 50,
    fontFamily: theme.fonts.family.primary,
    fontSize: "16px",
    fontWeight: "normal",
    border: "none",
    boxShadow: "none !important",
    color: "white !important",
    "&:hover": {
      background: "#e0d289",
    }
  }

}));
