import React, {useEffect, useState} from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {useStyles} from "../SettingsStyle";
import Button from "@material-ui/core/Button";
import CheckIcon from '@material-ui/icons/Check'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CreateIcon from '@material-ui/icons/Create';
import Status from "../../Revision/Status";
import {Link} from "react-router-dom";

const RequirementTable = ({refs, reload}) => {

    const classes           = useStyles();

    const checkout = (uuid) => {
        fetch(process.env.REACT_APP_BACKENDURL+'/requirement_rev/checkout/'+uuid).then(() => reload());
    }

    const checkin = (uuid) => {
        fetch(process.env.REACT_APP_BACKENDURL+'/requirement_rev/checkin/'+uuid).then(() => reload());
    }

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell align="right">Name</TableCell>
                        <TableCell align="right">Revision</TableCell>
                        <TableCell align="right">Status</TableCell>
                        <TableCell align="right">Datum</TableCell>
                        <TableCell align="right">Aktionen</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {refs && refs.map((row) => (
                        <TableRow key={row.uuid}>
                            <TableCell component="th" scope="row">
                                {row.id}
                            </TableCell>
                            <TableCell align="right">{row.name}</TableCell>
                            <TableCell align="right">{row.revision}</TableCell>
                            <TableCell align="right"><Status status={row.status}/></TableCell>
                            <TableCell align="right">{row.updated}</TableCell>
                            <TableCell align="right">
                                {row.status === "checkout" && <Button component={Link} to={'/settings/requirements/edit/'+row.uuid} size="small" variant="outlined" title="edit"><CreateIcon/></Button>}
                                {row.status === "checkout" && <Button size="small" variant="outlined" title="checkin" onClick={(id) => checkin(row.uuid)}><CheckIcon/></Button>}
                                {row.status === "edit" && <Button size="small" variant="outlined" title="checkout" onClick={(id) => checkout(row.uuid)}><ArrowDownwardIcon/></Button>}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};


export default RequirementTable;