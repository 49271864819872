import React, {useRef, useState} from 'react';
import { Editor } from '@tinymce/tinymce-react';
import Button from "@material-ui/core/Button";
import {useStyles} from "./EditorStyle";
import {useHistory} from "react-router";

const PdeEditor = ({value, setContent, updateUrl}) => {
    const editorRef                 = useRef(null);
    const classes                   = useStyles();
    const history                   = useHistory()
    const [isLoading, setIsLoading] = useState(false);
    const save = (goBack=false) => {
        if (editorRef.current) {
            setIsLoading(true);
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    content: editorRef.current.getContent(),
                })
            };

            fetch(updateUrl, requestOptions).then(() => setIsLoading(false)).then(() => goBack ? history.goBack() : {});
        }
    };
    return (
        <div>
            <div className={classes.buttonWrapper}>
                <Button className={classes.saveButton} variant="outlined" color="primary" disabled={isLoading} onClick={() => save(false)}>speichern</Button>
                <Button className={classes.saveAndBackButton} variant="outlined" color="secondary" disabled={isLoading} onClick={() => save(true)}>speichern + zurück</Button>
            </div>
            <Editor
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue={value}
                init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar: 'undo redo | formatselect | ' +
                        'bold italic backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />
        </div>
    );
}

export default PdeEditor;