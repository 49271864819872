import React, {useEffect, useState} from "react";
import { useStyles } from "./PhaseStyle";
import { useTranslation } from 'react-i18next';
import {CircularProgress} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import {useParams} from "react-router";
import PhaseCarousel from "./PhaseCarousel";


const PhaseOverview = () => {
  const classes                 = useStyles();
  const { uuid }                = useParams();
  const { t }                   = useTranslation();
  const [dhf, setDhf]   = useState();
  const [open, setOpen]         = useState(false);

    const reload = () => {
        setDhf(null);
        fetch(process.env.REACT_APP_BACKENDURL+'/dhf/'+uuid, {mode: "cors"})
            .then(dhf => dhf.json())
            .then(dhf => setDhf(dhf))
    }
    useEffect(() => {
        reload();
    }, []);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        reload();
    }

    const displayContent = () => {
        if(dhf) {
            return (
                <PhaseCarousel phases={dhf.phases}/>
            )
        } else {
            return <CircularProgress/>
        }
    }

  return (
        <div className={classes.MainContainer}>
          <div className={classes.SubMenu}>
            <a className={classes.HomeHeadingSecondary}>{t('projects')}</a>
            <span className={classes.divider}>/</span>
            <a className={classes.HomeHeadingSecondary}>{t('overview')}</a>
            <span className={classes.divider}>/</span>
            <a className={classes.HomeHeadingSecondary}>{dhf && dhf.project.name}</a>
            <span className={classes.divider}>/</span>
            <a className={classes.HomeHeadingSecondary}>{t('projectphases')}</a>
          </div>
          <h1 className={classes.MainHeading}>{t('projectphases')}</h1>
          <div className={classes.HomeWrapper}>
              {displayContent()}
          </div>
            <Button variant="contained" color="secondary" className={classes.button} startIcon={<AddIcon />} onClick={handleOpen}>{t('addrequirement')}</Button>
        </div>
  );
};

export default PhaseOverview;