import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Drawer from "../Components/Drawer/Drawer";
import React from 'react';
import Home from "../Components/Home/Home";
import Document from "../Components/Document/Document";
import Login from "../Components/Auth/Login";
import CompanyOverview from "../Components/CompanyOverview/CompanyOverview";
import CompanyProfile from "../Components/CompanyProfile/CompanyProfile";
import Project from "../Components/Projects/Project";
import PhaseOverview from "../Components/Phase/PhaseOverview";
import Settings from "../Components/Settings/Settings";
import Requirement from "../Components/Settings/Templates/Requirement";
import RequirementEditor from "../Components/Settings/Templates/RequirementEditor";


const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Login />
        </Route>
        <Route exact path="/home">
          <Drawer>
              <Home />
          </Drawer>
        </Route>
        <Route exact path="/settings">
          <Drawer>
            <Settings />
          </Drawer>
        </Route>
        <Route exact path="/settings/requirements">
          <Drawer>
            <Requirement />
          </Drawer>
        </Route>
        <Route exact path="/settings/requirements/edit/:uuid">
          <Drawer>
            <RequirementEditor />
          </Drawer>
        </Route>
        <Route path="/project/:uuid">
          <Drawer>
            <Project />
          </Drawer>
        </Route>
        <Route path="/phases/:uuid">
          <Drawer>
            <PhaseOverview />
          </Drawer>
        </Route>
        <Route exact path="/DocumentOverview">
          <Drawer>
            <Document />
          </Drawer>
        </Route>
        <Route exact path="/CompanyOverview">
          <Drawer>
            <CompanyOverview />
          </Drawer>
        </Route>
        <Route exact path="/CompanyProfile">
          <Drawer>
            <CompanyProfile />
          </Drawer>
        </Route>
      </Switch>
    </Router>
  );
};
export default Routes;