import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  MainContainer: {
    margin: "30px 0px 0px 30px",
    height: "100vh",
    position: "relative",
    width: "100%",
    ['@media (min-width: 2500px)']: {
      height: "88vh",
    },
    ['@media (min-width: 3000px)']: {
      height: "91vh",
    },
  },
  MainHeading: {
    fontFamily: theme.fonts.family.primary,
    fontSize: theme.fonts.sizes.font3XLL,
    fontWeight: 500,
    color: "#747474"
  },
  qsistantHeading: {
    fontFamily: theme.fonts.family.primary,
    fontSize: theme.fonts.sizes.fontL,
    color: "#333333",
    fontWeight: "normal",
    opacity: 0.7,
    cursor: "pointer",
  },

  divider: {
    margin: "0px 8px 0px 8px",
    color: "#777777"
  },
  DocHeading: {
    fontFamily: theme.fonts.family.primary,
    fontSize: theme.fonts.sizes.fontL,
    fontWeight: 500,
    color: "#000000",
    cursor: "pointer",
  },

  //Header Styling

  DocTableContainer: {
    background: "white",
    width: 1556,
    height: 247,
    padding: "10px 10px",
    ['@media (max-width: 1600px)']: {
      width: 1155,
      height: 247,
    },
    ['@media (min-width: 2000px)']: {
      width: "82vw",
    },
    ['@media (min-width: 2500px)']: {
      width: "85vw",
    },
    ['@media (min-width: 3000px)']: {
      width: "88vw",
    },
    ['@media (min-width: 3800px)']: {
      width: "90vw",
    },
  },
  HeaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  EntriesWrapper: {
    display: "flex",
    alignItems: "center",
    "& .react-numeric-input": {
      fontFamily: theme.fonts.family.primary,
      fontSize: theme.fonts.sizes.fontM,
      margin: "0px 10px",
      "& b": {
        border: "none !important",
        background: "transparent !important",
        boxShadow: "none !important",
        right: "7px !important",
        top: "10px !important",
        marginTop: "-1px !important"
      }
    }
  },
  ShowText: {
    fontFamily: theme.fonts.family.primary,
    fontSize: theme.fonts.sizes.fontM,
    color: "#000000",
    fontWeight: 500,
    margin: 0,
  },
  NumericInput: {
    width: 60,
    padding: "5px 10px",
    paddingLeft: "10px !important",
    "&:focus": {
      outline: "none"
    }
  },
  Entries: {
    fontFamily: theme.fonts.family.primary,
    fontSize: theme.fonts.sizes.fontM,
    color: "#000000",
    fontWeight: 500,
    margin: 0,
  },
  SearchWrapper: {
    display: "flex",
    alignItems: "center",
  },
  SearchLable: {
    fontFamily: theme.fonts.family.primary,
    fontSize: theme.fonts.sizes.fontM,
    color: "#000000",
    marginRight: 10,
  },
  SearchInput: {
    width: 200,
    height: 30,
  },
  button: {
    position: "absolute",
    left: "40%",
    bottom: "0px",
    marginLeft: "-50px",
    marginBottom: "10px",
    background: "#41bdb1 !important",
    fontFamily: theme.fonts.family.primary,
    fontSize: "14px !important",
    fontWeight: "normal !important",
    width: 206,
    height: 48,
    borderRadius: "24px !important",
    ['@media (min-width: 2500px)']: {
      left: "45%",
    },
    ['@media (min-width: 3800px)']: {
      left: "45%",
    },
  },

  //Dialog box styling 

  DialogContainer: {
    "& .MuiPaper-root": {
      width: 599,
    }
  },
  SubContainer: {
    margin: "15px 30px",
  },
  HeaderWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 15,

  },
  HeadingWrapper: {
    display: "flex",
    alignItems: "center",
  },
  DescriptionIcon: {
    fontSize: 20,
    color: "white",
    background: "#4599bf",
    padding: 8,
    borderRadius: 50,
    marginRight: 10,

  },
  Heading: {
    fontFamily: theme.fonts.family.primary,
    fontSize: theme.fonts.sizes.font2XL,
    fontWeight: 500,
    color: "rgba(0, 0, 0, 0.87)"
  },
  CloseIcon: {
    color: "black",
    opacity: 0.6,
    cursor: "pointer"
  },
  TitileInput: {
    width: "100%",
    marginBottom: 20,
  },

  //Select input styling
  FieldsWrapper: {
    marginTop: 45,
  },
  SelectContainer: {
    width: "100%",
    marginBottom: 20,
  },
  CreatButton: {
    width: "100%",
    height: 77,
    fontSize: "19px !important",
    color: "white !important",
    background: "#eee6be",
    boxShadow: "none !important",
    ":disabled": {
      backgroundColor: "gray"
    }
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));
