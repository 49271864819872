import React, {useEffect, useState} from 'react';
import Dialog from '../Dialog/Dialog'
import TextField from '@material-ui/core/TextField';
import { useStyles } from '../Document/DocumentStyle';
import {useTranslation} from "react-i18next";


const PhaseDialog = ({ dhf, handleClose, open, edit=false, value="", uuid=""}) => {
    const classes   = useStyles();
    const { t }     = useTranslation();

    let [name, setName]                 = useState(value);
    let [submittable, setSubmittable]   = useState(false);

    const fieldRender = () => {
        return (
            <TextField id="outlined-basic" label={t('phasename')} variant="outlined" value={name} onChange={handleNameChange} className={classes.TitileInput} />
        )
    }
    
    const submit = () => {
        const requestOptions = {
            method: edit ? 'PUT' : 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                name: name,
                dhf_revision: dhf ? dhf.revisions[dhf.revisions.length - 1].id : 0,
            })
        };

        let route = edit ? '/phase_rev/'+uuid : '/phase/';

        fetch(process.env.REACT_APP_BACKENDURL+route, requestOptions).then(values => values.json()).then(values => {
            setName("");
            handleClose();
        });
    }

    useEffect(() => {
        setSubmittable(name.length > 0);
    });

    const handleNameChange = (e) => {
        setName(e.target.value);
    }

    return (
        <Dialog handleClose={handleClose} open={open} submittable={submittable} handleFields={fieldRender} handleSubmit={submit} heading={t(edit ? 'editphase' : 'newphase')} submittitle={t(edit ? 'phaseedit' : 'phasecreate')} />
    );
}

export default PhaseDialog
