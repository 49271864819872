import React, {useEffect, useState} from "react";
import { useStyles } from "../SettingsStyle";
import { useTranslation } from 'react-i18next';
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import RequirementDialog from "./RequirementDialog";
import RequirementTable from "./RequirementTable";


const Requirement = () => {
  const classes                 = useStyles();
  const { t }                   = useTranslation();
  const [open, setOpen]         = useState(false);
  const [refs, setRefs]         = useState([]);
  const [current, setCurrent]         = useState();

    const reload = () => {
        fetch(process.env.REACT_APP_BACKENDURL+'/requirement_rev/', {mode: "cors"})
            .then(requirements => requirements.json())
            .then(requirements => setRefs(requirements))
    }

    useEffect(() => {
        reload();
    }, []);

  const handleOpen = () => {
        setOpen(true);
  }

  const handleClose = () => {
        setOpen(false);
        reload();
  }

  return (
    <div className={classes.MainContainer}>
      <div className={classes.SubMenu}>
        <a className={classes.HomeHeadingSecondary}>{t('settings')}</a>
        <span className={classes.divider}>/</span>
        <a className={classes.HomeHeading}>{t('requirements')}</a>
      </div>
      <h1 className={classes.MainHeading}>{t('requirements')}</h1>
      <div className={classes.HomeWrapper}>
          {refs && <RequirementTable refs={refs} reload={reload} />}
          <RequirementDialog open={open} handleClose={handleClose} requirement={current}/>
          <Button variant="contained" color="secondary" className={classes.button} startIcon={<AddIcon />} onClick={handleOpen}>{t('addrequirement')}</Button>
      </div>
    </div>
  );
};

export default Requirement;