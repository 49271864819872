import React, { useState } from "react";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import { useStyles } from "./CompanyOverviewStyle";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Popup from "./Popup";


const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);



const CompanyOverview = () => {

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();
  return (
    <div className={classes.MainContainer}>
      <h1 className={classes.MainHeading}>Firmenübersicht</h1>
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs>
            <Paper className={classes.paper} onClick={() => alert("card Fired")}>
              <div className={classes.PaperInner}>
                <StyledBadge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  variant="dot"
                  className={classes.AvatarWrapper}
                >
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" className={classes.Avatar} />
                </StyledBadge>
                <h3 className={classes.AvatarHeading}>Cetrumed GmbH</h3>
                <div className={classes.StatusWrapper}>
                  <p className={classes.Status}>Online</p>
                </div>
                <div className={classes.VersionWrapper}>
                  <div>
                    <p className={classes.Version}>Version</p>
                    <h3 className={classes.VersionNumber}>2.0.0</h3>
                  </div>
                  <div className={classes.LicensesWrapper}>
                    <p className={classes.Licenses}>Lizenzen</p>
                    <h3 className={classes.LicensesNumber}>7/9</h3>
                  </div>
                  <div>
                    <p className={classes.Api}>Api</p>
                    <h3 className={classes.VersionControl}>v1</h3>
                  </div>
                </div>
                <Button className={classes.showAccBtn} onClick={(e) => { e.stopPropagation(); alert("show account button") }}>Account anzeigen</Button>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <Popup open={open} handleClose={handleClose} />
      <Button
        variant="contained"
        color="secondary"
        className={classes.button}
        startIcon={<AddIcon />}
        onClick={handleClickOpen}
      >
        Firma hinzufügen
      </Button>
    </div>
  )
}

export default CompanyOverview