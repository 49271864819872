import React, {useEffect, useState} from "react";
import { useStyles } from "./FolderDetailsStyle";
import FolderEntry from "./FolderEntry";
import AddIcon from "@material-ui/icons/Add";
import FolderIcon from "@material-ui/icons/FolderOpen";

const FolderDetails = ({project, ...props}) => {
    const classes = useStyles();
    const [folders, setFolders] = useState();
    let usedFolders = [];

    useEffect(() => {
        fetch(process.env.REACT_APP_BACKENDURL+'/folder/'+project.uuid).then(entries => entries.json()).then(folders => setFolders(folders))
    }, []);

    const displayFolder = (folder, prefix= 0) => {
        if(folder && folder.length > 0) {
            return folder.map((f) => {
                usedFolders.push({
                    prefix: prefix,
                    folder: f,
            });
                if(f.children) {
                    displayFolder(f.children, prefix + 1);
                }
            })
        }
        return true;
    }

    return (
        <div className={classes.root}>
            <h2>Ordner ({usedFolders ? usedFolders.length : 0}) <AddIcon className={classes.folderAdd} onClick={props.dialog}/></h2>
            {folders && displayFolder(folders) && usedFolders.map((f) =>
                <div style={{paddingLeft: (f.prefix*20+10)+"px"}}><FolderEntry folder={f.folder}/></div>
            )}
        </div>
    );
};

export default FolderDetails;