import React, { useState, useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import { useStyles } from "./DrawerStyle";
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import clsx from "clsx";
import { FirstSectionArray} from "./DrawerArray"
import { useHistory, useLocation } from "react-router";
import NavBar from "../NavBar/NavBar";
import {useTranslation} from "react-i18next";


const PersistentDrawerLeft = ({ children }) => {

  // const Location = useLocation();

  // console.log(Location, "here")

  const classes = useStyles();
  const History = useHistory();
  const { t, i18n } = useTranslation();

  // const [path, setPath] = useState('');
  const [Active, setActive] = useState(1);
  const [menuEntries, setMenuEntries] = useState([]);

  return (
    <>
      <NavBar />
      <div className={classes.root}>
        <Drawer
          className={classes.MainContainer}
          anchor="left"
          variant="persistent"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.DrawerTopSection}>
            {
              FirstSectionArray.map((i) => {
                return (
                  <ListItem key={i.Name} className={clsx(classes.ListWrapper, Active === i.Name ? classes.Active : classes.InActive)} onClick={() => {
                    History.push(`${i.path}`)
                    setActive(i.Name)
                  }}>
                    <ListItemIcon className={classes.DrawerIcon}><i.icon /></ListItemIcon>
                    <ListItemText className={classes.ListName}>{t(i.Name)}</ListItemText>
                  </ListItem>
                )
              })
            }
          </div>
        </Drawer>
        {children}
      </div>
    </>
  );
}

export default PersistentDrawerLeft;