import React, {useEffect, useState} from "react";
import { useStyles } from "./HomeStyle";
import { useTranslation } from 'react-i18next';
import {CircularProgress} from "@material-ui/core";
import ProjectCard from "../Projects/ProjectCard";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import DocDialog from "../Home/ProjectDialog";


const Home = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [entries, setEntries] = useState([]);
  const [reloads, setReloads] = useState(0);

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        reload();
    };

    const reload = () => {
        setEntries([]);
        fetch(process.env.REACT_APP_BACKENDURL+'/project/', {mode: "cors"})
            .then(entries => entries.json())
            .then(entries =>setEntries(entries))
    }
    useEffect(() => {
        reload();
    }, []);

  const displayData = () => {
      if(entries.length === 0) {
          return <CircularProgress />
      } else {
          return entries.map((i) => {return <div><ProjectCard key={i.id} item={i}/><br/></div>})
      }
  }

  return (
    <div className={classes.MainContainer}>
      <div className={classes.SubMenu}>
        <a className={classes.HomeHeadingSecondary}>{t('projects')}</a>
        <span className={classes.divider}>/</span>
        <a className={classes.HomeHeading}>{t('overview')}</a>
      </div>
      <h1 className={classes.MainHeading}>{t('projectoverview')}</h1>
      <div className={classes.HomeWrapper}>
          {displayData()}
      </div>
        <DocDialog open={open} handleClose={handleClose} />
        <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            startIcon={<AddIcon />}
            onClick={handleClickOpen}
        >
            Neues Projekt
        </Button>
    </div>
  );
};

export default Home;