import React, {useEffect, useState} from "react";
import { useStyles } from "./FolderDetailsStyle";
import FolderIcon from "@material-ui/icons/FolderOpen"

const FolderEntry = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <FolderIcon className={classes.folderIcon} /> {props.folder.name}
        </div>
    );
};

export default FolderEntry;