import React, {useEffect, useState} from "react";
import {CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {useStyles} from "./PhaseStyle";
import Button from "@material-ui/core/Button";
import CheckIcon from '@material-ui/icons/Check'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CreateIcon from '@material-ui/icons/Create';
import Status from "../Revision/Status";
import {Link} from "react-router-dom";
import PhaseDialog from "./PhaseDialog";

const PhaseEdit = ({value, uuid, reload}) => {

    const classes           = useStyles();
    const [open, setOpen]    = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        reload();
    }

    return (
        <div>
            <PhaseDialog open={open} handleClose={handleClose} edit={true} value={value} uuid={uuid}/>
            <Button onClick={handleOpen} size="small" variant="outlined" title="edit"><CreateIcon/></Button>
        </div>
    );
};


export default PhaseEdit;