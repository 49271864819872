import React, { useState } from "react";
import { useStyles } from "./DocumentStyle";
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import DocDialog from "./DocDialog";




const Document = () => {

  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.MainContainer}>
      <div className={classes.SubMenu}>
        <a className={classes.qsistantHeading}>Qsistant</a>
        <span className={classes.divider}>/</span>
        <a className={classes.DocHeading}>Dateiverwaltung</a>
      </div>
      <h1 className={classes.MainHeading}>Dokumentenübersicht</h1>
      <div className={classes.DocTableContainer}>
      </div>
      <DocDialog open={open} handleClose={handleClose} />
      <Button
        variant="contained"
        color="secondary"
        className={classes.button}
        startIcon={<AddIcon />}
        onClick={handleClickOpen}
      >
        Neues dokument
      </Button>
    </div>
  )
}

export default Document