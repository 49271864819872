import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  MainContainer: {
    background: "white",
    height: 95,
  },
  Wrapper: {
    padding: "30px 16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  Logo: {
    fontFamily: theme.fonts.family.primary,
    fontSize: 40,
    color: "#4e4d4d",
    fontWeight: "normal",
    margin: 0,
  },
  searchContainer: {
    borderRadius: theme.shape.borderRadius,
    marginLeft: 0,
    background: "#f3f3f3",
    display: "flex",
    alignItems: "center",
    width: 304,
    height: 48,
    padding: "0px 7px",
  },
  Input: {
    width: 304,
    fontFamily: "Roboto !important",
    fontSize: "12px !important",

  },
  searchIcon: {
    color: "#000000",
    fontSize: 20,
    margin: 5,
    opacity: 0.54,
  },
  Avatar: {
    marginRight: 10,
    width: 34,
    height: 34

  },
  formControl: {
    width: 77,
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      border: "none"
    },
    "&.MuiSelect-select:focus": {
      background: "blue"
    },
  },
  selectEmpty: {
    fontFamily: theme.fonts.family.primary,
    "&.MuiInput-underline:before": {
      borderBottom: "none",
    },
    "&.MuiInput-underline:after": {
      borderBottom: "none"
    },
    "& .MuiSelect-select": {
      fontFamily: theme.fonts.family.primary,
      fontSize: theme.fonts.sizes.fontS,
      "&.MuiSelect-select:focus": {
        background: "transparent",

      }
    },
  },
  DefaultValue: {

  },
  subMenuContainer: {
    display: "flex",
    alignItems: "center",
    border: "solid 1px #e0e0e0",
    borderRadius: 23,
    padding: "3px 5px"
  },

  //AppBar styling 

  AppBar: {
    background: "white !important",
    boxShadow: "none !important",
    height: 14,
    transition: "none",
    "& .PrivateTabIndicator-colorSecondary-23": {
      backgroundColor: "#e0d289 !important",
      height: 4,
      width: "90px !important",
      margin: "0px 0px 0px 20px",
    },

  },
  ListItem: {
    minWidth: "130px !important",
    fontSize: theme.fonts.sizes.fontL,
    textTransform: "none !important",
    color: "rgba(0, 0, 0, 0.8)!important",
    "& .MuiTouchRipple-root": {
      display: "none"
    },
    "& .MuiTab-wrapper": {
      marginTop: "-32px",

    }
  }
}));
