import React, {useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DescriptionIcon from '@material-ui/icons/Description';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useStyles } from '../Document/DocumentStyle';
import {useTranslation} from "react-i18next";
import {CircularProgress} from "@material-ui/core";


const CustomizedDialogs = ({ open, handleClose, project }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  let [name, setName] = useState("");
  const [loading, setLoading] = React.useState(false);
  let [submitable, setSubmitable] = useState(false);

  const submitDhf = () => {
    setLoading(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        name: name,
        project: project.id
      })
    };

    fetch(process.env.REACT_APP_BACKENDURL+'/folder/', requestOptions).then(values => values.json()).then(values => {
      setName("");
      handleClose();
      setLoading(false);
    }).catch(e => {
      setLoading(false);
    });
  }

  useEffect(() => {
    setSubmitable(name.length > 0);
  });

  const handleNameChange = (e) => {
    setName(e.target.value);
  }

  return (
    <Dialog aria-labelledby="customized-dialog-title" open={open} className={classes.DialogContainer}>
      <div className={classes.SubContainer}>
        <div className={classes.HeaderWrapper}>
          <div className={classes.HeadingWrapper}>
            <DescriptionIcon className={classes.DescriptionIcon} />
            <h2 className={classes.Heading}>{t('newfolder')}</h2>
          </div>
          <CloseIcon className={classes.CloseIcon} onClick={handleClose} />
        </div>
        <div className={classes.FieldsWrapper}>
          <TextField id="outlined-basic" label={t('foldername')} variant="outlined" value={name} onChange={handleNameChange} className={classes.TitileInput} />
        </div>
        <div className={classes.wrapper}>
          <Button variant="contained" disabled={!submitable && !loading} className={classes.CreatButton} onClick={submitDhf}>
            {t('foldercreate')}
          </Button>
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </div>
    </Dialog>
  );
}

export default CustomizedDialogs
