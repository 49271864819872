
import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useStyles } from "./NavbarStyle";
import { useHistory } from "react-router";


const SimpleTabs = () => {

  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const History = useHistory();


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root} >
      <AppBar position="static" className={classes.AppBar}  >
        <Tabs value={value} onChange={handleChange}  >
          <Tab label="Qsistant" className={classes.ListItem} />
          <Tab label="Verwaltung" className={classes.ListItem} />
          <Tab label="Profil" className={classes.ListItem} onClick={() => History.push("/CompanyProfile")} />
          <Tab label="Support" className={classes.ListItem} />
        </Tabs>
      </AppBar>
    </div>
  );
}

export default SimpleTabs

