import React from "react";
import { useStyles } from "./NavbarStyle";
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Tabs from "./Tabs"


const NavBar = () => {
  const classes = useStyles();
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <div className={classes.MainContainer}>
      <div className={classes.Wrapper}>
        <h1 className={classes.Logo}><strong>Q</strong>sistant</h1>
        <div className={classes.ListContainer}>
        </div>
        <Tabs />
        <div className={classes.searchContainer}>
          <div className={classes.searchWrapper}>
            <SearchIcon className={classes.searchIcon} />
          </div>
          <InputBase
            placeholder="Suche"
            className={classes.Input}
          />
        </div>
        <div className={classes.subMenuContainer}>
          <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" className={classes.Avatar} />
          <FormControl className={classes.formControl}>
            <Select
              value={age}
              onChange={handleChange}
              displayEmpty
              className={classes.selectEmpty}
            >
              <MenuItem value="">
                Maxime
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  )
}

export default NavBar