import React, {useEffect, useState} from "react";
import { useStyles } from "./ProjectStyle";
import { useTranslation } from 'react-i18next';
import {CircularProgress} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import {useParams} from "react-router";
import DhfDetails from "../Dhf/DhfDetails";
import FolderDetails from "../Folder/FolderDetails";
import DhfDialog from "../Dhf/DhfDialog";
import FolderDialog from "../Folder/FolderDialog";


const Project = () => {
  const classes                 = useStyles();
  const { uuid }                = useParams();
  const { t }                   = useTranslation();
  const [project, setProject]   = useState();
  const [open, setOpen]         = useState(false);
  const [folderOpen, setFolderOpen]         = useState(false);

    const reload = () => {
        setProject(null);
        fetch(process.env.REACT_APP_BACKENDURL+'/project/details/'+uuid, {mode: "cors"})
            .then(project => project.json())
            .then(project => setProject(project))
    }
    useEffect(() => {
        reload();
    }, []);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        reload();
    }

    const handleFolderOpen = () => {
        setFolderOpen(true);
    }

    const handleFolderClose = () => {
        setFolderOpen(false);
        reload();
    }

    const displayContent = () => {
        if(project) {
            return (
                <div style={{width: "100%"}}>
                    <img src={project.image ? process.env.REACT_APP_BACKENDURL+'/file/download/'+project.image : "https://weber-instrumente.com/library/pg-orthopaedische-instrumente/orth02.png"} style={{maxWidth: "300px"}}/>
                    <DhfDetails dhf={project.dhf} dialog={handleOpen}/>
                    <FolderDetails project={project} dialog={handleFolderOpen}/>
                </div>
            )
        } else {
            return <CircularProgress/>
        }
    }
  const displayAddButton = () => {
        if(project && !project.dhf) {
            return (
                <div>
                    <DhfDialog open={open} handleClose={handleClose} project={project} />
                    <FolderDialog open={folderOpen} handleClose={handleFolderClose} project={project} />
                    <Button variant="contained" color="secondary" className={classes.button} startIcon={<AddIcon />} onClick={handleOpen}> DHF erstellen </Button>
                </div>
            )
        } else return <FolderDialog open={folderOpen} handleClose={handleFolderClose} project={project} />;
  }
  return (
    <div className={classes.MainContainer}>
      <div className={classes.SubMenu}>
        <a className={classes.HomeHeadingSecondary}>{t('projects')}</a>
        <span className={classes.divider}>/</span>
        <a className={classes.HomeHeadingSecondary}>{t('overview')}</a>
        <span className={classes.divider}>/</span>
        <a className={classes.HomeHeading}>{project && project.name}</a>
      </div>
      <h1 className={classes.MainHeading}>{t('projectdetails')}</h1>
      <div className={classes.HomeWrapper}>
          {displayContent()}
      </div>
        {displayAddButton()}
    </div>
  );
};

export default Project;