import React from "react";
import { useStyles } from "./CompanyProfileStyle";
import Avatar from '@material-ui/core/Avatar';
import CompanyRow from "./CompnayRow";

const CompanyProfile = () => {
  const classes = useStyles();

  return (
    <div className={classes.MainContainer}>
      <div className={classes.SubMenu}>
        <a className={classes.ProfilHeading}>Profil</a>
        <span className={classes.divider}>/</span>
        <a className={classes.overviewHeading}>Profilübersicht</a>
      </div>
      <h1 className={classes.MainHeading}>Profilübersicht</h1>
      <div className={classes.ProfileContainer}>
        <div className={classes.profileInnerContent}>
          <div className={classes.AvatarWrapper}>
            <Avatar src="/broken-image.jpg" className={classes.ProfileAvatar} />
            <div className={classes.userNameWrapper}>
              <p className={classes.UserName}>Name</p>
              <p className={classes.Maxime}>Maxime Mustermann</p>
            </div>
          </div>
          <div className={classes.AbbreviationWrapper}>
            <p className={classes.Abbreviation}>Kürzel</p>
            <p className={classes.subText}>MM</p>
          </div>
          <div className={classes.ChangeWrapper}>
            <p className={classes.ChangeText}>Ändern</p>
          </div>
        </div>
      </div>
      <CompanyRow />
    </div>
  )
}

export default CompanyProfile