import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translation from './de-DE/translation.json';

// the translations
const resources = {
    de: {
        translation: translation
    }
};

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        debug: true,
        fallbackLng: 'de-DE',
        defaultNS: 'translation',
        react: {
            useSuspense: false,
        }
    });

export default i18n;