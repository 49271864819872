import React from "react";
import { useStyles } from "./LoginStyle";
import Input from "./Input";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router";


const Login = () => {

  const classes = useStyles();
  const History = useHistory();

  return (
    <div className={classes.MainContainer}>
      <div className={classes.LogoWrapper}>
        <h1 className={classes.Logo}><strong style={{ color: "black" }}>Q</strong>sistant</h1>
      </div>
      <div className={classes.LoginContainer}>
        <h1 className={classes.FormHeading}>Hey, willkommen zurück.</h1>
        <h4 className={classes.subTitle}>Logge dich in QSISTANT ein, um fortzufahren.</h4>
        <Input />
        <div className={classes.CheckboxWrapper}>
          <FormControlLabel control={<Checkbox name="checkedC" />} label="Passwort merken" className={classes.Checkbox} />
          <a className={classes.forgetPassword}>Passwort vergessen?</a>
        </div>
        <Button variant="contained" className={classes.LoginBtn} onClick={() => History.push("/home")}>
          Anmelden
        </Button>
      </div>
    </div>
  )
}

export default Login