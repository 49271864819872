import React, {useEffect, useState} from "react";
import { useStyles } from "./DhfDetailsStyle";
import PhaseTable from "../Phase/PhaseTable";
import DhfRevision from "./DhfRevision";

const DhfDetails = (props) => {
    const classes = useStyles();
    const [rev, setRev] = useState(props.dhf.current);
    const dhf = props.dhf;

    const renderContent = () => {
        if(rev) {
            return (
                <div>
                    <h2>Entwicklungsakte</h2>
                    <DhfRevision rev={rev}/>
                    <h2>Phasen</h2>
                    <PhaseTable dhf={dhf} />
                </div>
            );
        } else {
            return "Keine Entwicklungsakte vorhanden";
        }
    }

    return (
        <div className={classes.root}>
            {renderContent()}
        </div>
    );
};

export default DhfDetails;