import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  MainContainer: {
    margin: "50px 0px 0px 30px",
    height: "100vh",
    position: "relative",
    width: "100%",
    ['@media (min-width: 2500px)']: {
      height: "88vh",
    },
    ['@media (min-width: 3000px)']: {
      height: "90vh",
    },
  },
  MainHeading: {
    fontFamily: theme.fonts.family.primary,
    fontSize: theme.fonts.sizes.font3XLL,
    color: "#747474",
    fontWeight: 500,
  },
  root: {
    flexGrow: 1,
    "& .MuiGrid-spacing-xs-4": {
      width: '100% !important',
    }
  },
  paper: {
    width: 364,
    height: 451,
    background: "#ffffff",
    borderRadius: 8,
    boxShadow: "none",
    cursor: "pointer",

  },
  PaperInner: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  AvatarWrapper: {
    marginTop: "3rem"
  },
  Avatar: {
    height: 80,
    width: 80,
  },
  AvatarHeading: {
    fontFamily: theme.fonts.family.primary,
    fontSize: 22,
    fontWeight: 500,
    color: "rgba(0, 0, 0, 0.87)"
  },
  StatusWrapper: {
    border: "solid 1.5px #5d5d5d",
    borderRadius: 10,
    padding: "4px 20px",

  },
  Status: {
    fontFamily: theme.fonts.family.primary,
    fontSize: theme.fonts.sizes.fontM,
    color: "#000000",
    fontWeight: "bold",
    margin: 0,
  },
  VersionWrapper: {
    display: "flex",
    flexDirection: "row",
    margin: "20px 0px"
  },
  Version: {
    fontFamily: theme.fonts.family.primary,
    fontSize: theme.fonts.sizes.fontM,
    color: "#000000",
    opacity: 0.5,
  },
  VersionNumber: {
    fontFamily: theme.fonts.family.primary,
    fontSize: 34,
    fontWeight: 500,
    margin: 0,
    color: "rgba(0, 0, 0, 0.87)",
  },
  LicensesWrapper: {
    margin: "0px 40px"
  },
  Licenses: {
    fontFamily: theme.fonts.family.primary,
    fontSize: theme.fonts.sizes.fontM,
    color: "#000000",
    fontWeight: 500,
    opacity: 0.5,
  },
  LicensesNumber: {
    fontFamily: theme.fonts.family.primary,
    fontSize: 34,
    margin: 0,
    fontWeight: 500,
    color: "rgba(0, 0, 0, 0.87)",
  },
  Api: {
    fontFamily: theme.fonts.family.primary,
    fontSize: theme.fonts.sizes.fontM,
    color: "#000000",
    fontWeight: 500,
    opacity: 0.5,
  },
  VersionControl: {
    fontFamily: theme.fonts.family.primary,
    fontSize: 34,
    margin: 0,
    fontWeight: 500,
    color: "rgba(0, 0, 0, 0.87)",
  },
  showAccBtn: {
    fontFamily: theme.fonts.family.primary,
    color: "#45bdb0",
    fontSize: 18,
    marginTop: 30,
  },
  DialogContainer: {
    "& .MuiPaper-root": {
      width: 599,
      height: 600,
    }
  },

  button: {
    position: "absolute",
    left: "40%",
    bottom: "0px",
    marginLeft: "-50px",
    marginBottom: "10px",
    background: "#41bdb1 !important",
    fontFamily: theme.fonts.family.primary,
    fontSize: "14px !important",
    fontWeight: "normal !important",
    width: 206,
    height: 48,
    borderRadius: "24px !important",
    ['@media (min-width: 2500px)']: {
      left: "45%",
    },
    ['@media (min-width: 3800px)']: {
      left: "45%",
    },
  },
  SubContainer: {
    margin: "15px 30px",
  },
  HeaderWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 15,
  },
  HeadingWrapper: {
    display: "flex",
    alignItems: "center",
  },
  DescriptionIcon: {
    fontSize: 20,
    color: "white",
    background: "#45bdb0",
    padding: 8,
    borderRadius: 50,
    marginRight: 10,
  },
  Heading: {
    fontFamily: theme.fonts.family.primary,
    fontSize: theme.fonts.sizes.font2XL,
    fontWeight: 500,
    color: "rgba(0, 0, 0, 0.87)"
  },
  CloseIcon: {
    color: "black",
    opacity: 0.6,
    cursor: "pointer"
  },
}));
